import * as React from 'react';
import { graphql } from 'gatsby';
import Footer from '../components/footer/footer';
import GlobalStyles from '../styles/globalStyles';
import {
  HtmlSitemapMainDiv,
  HtmlSiteMapHeader,
  HtmlSitemapLink,
} from '../styles';
import BootstrapNavbar from '../components/navigationBar/mainMenu/navigationBar';
import { addTrailingSlash } from '../utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import ActiveResidentialNav from '../images/head-nav-active-residential.png';
import ActiveCommercialNav from '../images/head-nav-active-commercial.png';
import DefaultHeaderMenu from '../components/defaultHeaderMenu/defaultHeaderMenu';

const HtmlSitemap = ({ data, location }) => {
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const path = location?.pathname?.split('/')[1];
  const [showRequestCallBackProp, setShowRequestCallBackProp] =
    React.useState(false);
  const handleRequestCallBack = () => {
    setShowRequestCallBackProp(true);
  };
  const pageType = location?.pathname?.split('/').find((n) => n);
  const MenuBarColor = {
    residential: {
      background: '#b2bc73',
      border: '#737c47',
      defaultCardBorderColor: '#ECEEDC',
      activePathImage: ActiveResidentialNav,
    },
    commercial: {
      background: '#ce903c',
      border: '#a46b2c',
      defaultCardBorderColor: '#F0DFC8',
      activePathImage: ActiveCommercialNav,
    },
    locations: {
      background: '#049de1',
      border: '#037ec4',
      defaultCardBorderColor: '#CDE5F3',
    },
    about: {
      background: '#049de1',
      border: '#037ec4',
      defaultCardBorderColor: '#CDE5F3',
    },
    contact: {
      background: '#049de1',
      border: '#037ec4',
      defaultCardBorderColor: '#CDE5F3',
    },
  };

  const allSlugs = [
    ...data?.allContentfulFasGasPlus?.edges
      .filter((edge) => {
        if (
          edge?.node?.slug &&
          !edge?.node?.slug?.includes('modal') &&
          !edge?.node?.slug?.includes('{city}') &&
          !edge?.node?.title.includes('Header')
        ) {
          return edge?.node?.slug;
        }
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(edge?.node?.slug),
          title: edge?.node?.title,
        };
      }),
  ].sort((a, b) => a?.slug?.localeCompare(b?.slug));

  const SlugList = allSlugs.filter(function (x, i, a) {
    return a.indexOf(x) === i;
  });

  return (
    <>
      <Helmet>
        <title> Fas Gas Plus :: Site Map </title>
      </Helmet>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
        activeLink={pageType}
        activePathImage={MenuBarColor[pageType]?.activePathImage}
        activePathImageTop={MenuBarColor[pageType]?.border}
      />
      <HtmlSitemapMainDiv>
        {SlugList.map((slug, index) => (
          <HtmlSiteMapHeader
            $leftSpace={slug?.slug?.split('/')?.length - 1}
            key={index}
          >
            <HtmlSitemapLink href={process.env.GATSBY_DOMAIN_URL + slug?.slug}>
              {slug?.title}
            </HtmlSitemapLink>
          </HtmlSiteMapHeader>
        ))}
      </HtmlSitemapMainDiv>

      <Footer />
    </>
  );
};

export default HtmlSitemap;
export const pageQuery = graphql`
  query SitemapQuery {
    allContentfulFasGasPlus(
      filter: { node_locale: { eq: "en-US" }, hasIndex: { ne: false } }
    ) {
      edges {
        node {
          slug
          title
          name
        }
      }
    }
  }
`;

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import SearchSite from '../searchSite/searchSite';
import useDefaultMenus from '../../hooks/useDefaultMenus';
import { BsSearch} from '@react-icons/all-files/bs/BsSearch';
import { VscChromeClose } from '@react-icons/all-files/vsc/VscChromeClose';
import * as Styled from './defaultHeaderMenuStyle';
import constants from '../../utils/utils';
import { useContentfulImages } from '../../hooks/useContentfulImages'
import isExternalUrl, {
  isExternalUrlhref,
  addTrailingSlash,
} from '../../utils';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const DefaultHeaderMenu = () => {
  const menu = useDefaultMenus();

  const [showSearchSite, setShowSearch] = React.useState(false);
  const [placeholder,setPlaceholderColor] = React.useState(-1);
  const closeSearch = () => {
    setShowSearch(false);
  };
  const searchSiteClick = () => {
    setShowSearch(!showSearchSite);
    setPlaceholderColor(placeholder + 1);
  };

  const optionsMainStyle = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        if (asset) {
          return (
            <Styled.BlueButton href={
              isExternalUrlhref(asset?.node?.file?.url)
                ? asset?.node?.file?.url
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + asset?.node?.file?.url
                  )
            }
            target={isExternalUrl(asset?.node?.file?.url) ? '_blank' : '_self'}
            >
              {children}
            </Styled.BlueButton>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.DefaultHeaderContainer>
      <Styled.InnerContainer>
        <Row className="g-0 justify-content-md-end justify-content-lg-end">
          {menu.listOfImagesWithPath.map((item, index) => {
            return (
              <Col xs="auto" key={index}>             
                {item.title ===
                        constants.DEFAULT_MENU.FACEBOOK && (
                        <Styled.TransparentButton>
                          <Styled.BlueButton 
                          href={
                              isExternalUrlhref(item?.path)
                              ? item?.path
                              : addTrailingSlash( process.env.GATSBY_DOMAIN_URL + item?.path )
                          }
                          target={isExternalUrl(item?.path) ? '_blank' : '_self'}>
                            <span >
                            {item.title}
                            </span>
                            <Styled.Img src={item.image.file.url} alt={item.title} title={item.title} />
                          </Styled.BlueButton>
                        </Styled.TransparentButton>
                )}
                {item.title === constants.DEFAULT_MENU.SEARCH_SITE && (
                  <Styled.TransparentButton
                    onClick={() => searchSiteClick()}
                    className={showSearchSite && 'active'}
                  >                  
                    <span>{item.title}</span>
                    {showSearchSite ? (
                      <VscChromeClose
                        color="#ffe400"
                        size="16px"
                        className="icon-margin-right"
                      />
                    ) : (
                      <BsSearch
                        color="#ffe400"
                        size="16px"
                        className="icon-margin-right"
                      />

                    )}

                  </Styled.TransparentButton>
                )}
              </Col>
            );
          })}
        </Row> 
      </Styled.InnerContainer>
      {showSearchSite && (
        <Styled.SearchContainer fluid>
          <SearchSite placeholder = {placeholder}/>
        </Styled.SearchContainer>
      )}
    </Styled.DefaultHeaderContainer>
  );
};
export default DefaultHeaderMenu;

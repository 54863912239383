import { graphql, useStaticQuery } from 'gatsby';
import constants from '../utils/utils';

export default () => {
  const { allContentfulFasGasPlus } = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulFasGasPlus(
          filter: {
            identifier: { type: { eq: "header" }, node_locale: { eq: "en-US" } }
          }
        ) {
          edges {
            node {
              title
              name
              slug
              identifier {
                type
              }
              sections {
                ... on ContentfulListOfImageWithPath {
                  id
                  title
                  type
                  listOfImagesWithPath {
                    title
                    type
                    path
                    richText {
                      raw
                    }
                    image {
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allContentfulFasGasPlus.edges[0].node.sections.filter(
    (item) => item.type === 'imageWithPath'
  )[0];
};

import { graphql, useStaticQuery } from 'gatsby';
import constants from '../utils/utils';

export default () => {
  const { allContentfulFasGasPlus } = useStaticQuery(
    graphql`
    query commonfooter {
      allContentfulFasGasPlus(
        filter: {
          identifier: {type: {eq: "footer"}}
          node_locale: {eq: "en-US"}}
      ) {
        edges {
          node {
            title
            name
            slug
            identifier {
              type
            }
            sections {
              ... on ContentfulFooter {
                id
                title
                type
                contactInformation {
                  header
                  title
                  type
                  contentDetails {
                    raw
                  }
                }
                TextWithPaths {
                  text
                  type
                  path
                }
              }
            }
          }
        }
      }
    }
    `
  );
  return allContentfulFasGasPlus.edges[0].node.sections.filter(
    (item) => item.type === constants.COMMON.FOOTER
  )[0];
};

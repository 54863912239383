import * as React from 'react';
import * as Styled from './footerStyles';
import useFooter from '../../hooks/useFooter';
import isExternalUrl, { isExternalUrlhref,addTrailingSlash } from '../../utils';
import constants from '../../utils/utils';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Footer = ({ showProducts }) => {
  const footer = useFooter();
  const optionsMainStyleVideo = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.AnchorStyle
          href={
            isExternalUrlhref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.AnchorStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      <Styled.FooterContainer>
        <Styled.FooterInnerContainer>
          <Styled.FooterLeft>
            <Styled.HeaderH2>
              {footer.contactInformation[0].header}
            </Styled.HeaderH2>
              {documentToReactComponents(
                  JSON.parse(footer.contactInformation[0].contentDetails.raw),
                  optionsMainStyleVideo
              )}
          </Styled.FooterLeft>
          <Styled.FootMiddle>
            <Styled.HeaderH2>
              {footer.contactInformation[1].header}
            </Styled.HeaderH2>
            <Styled.FootMiddleLeft>
              {documentToReactComponents(
                    JSON.parse(footer.contactInformation[1].contentDetails.raw),
                    optionsMainStyleVideo
                )}
            </Styled.FootMiddleLeft>
            <Styled.FootMiddleRight>
              {documentToReactComponents(
                    JSON.parse(footer.contactInformation[2].contentDetails.raw),
                    optionsMainStyleVideo
                )}
            </Styled.FootMiddleRight>
            <Styled.Clear>&nbsp;</Styled.Clear>
          </Styled.FootMiddle>
          <Styled.FootRight>
            <Styled.HeaderH2>
              {footer.contactInformation[3].header}
            </Styled.HeaderH2>
            <Styled.AnchorStyle href={
                              isExternalUrlhref(footer?.TextWithPaths[0].path)
                              ? footer?.TextWithPaths[0].path
                              : addTrailingSlash( process.env.GATSBY_DOMAIN_URL + footer?.TextWithPaths[0].path )
                          }
                          target={isExternalUrl(footer?.TextWithPaths[0].path) ? '_blank' : '_self'}
              >
              { footer.TextWithPaths[0].text }

            </Styled.AnchorStyle>
            <Styled.Clear></Styled.Clear>
            <Styled.AnchorStyle href={
                              isExternalUrlhref(footer?.TextWithPaths[1].path)
                              ? footer?.TextWithPaths[1].path
                              : addTrailingSlash( process.env.GATSBY_DOMAIN_URL + footer?.TextWithPaths[1].path )
                          }
                          target={isExternalUrl(footer?.TextWithPaths[1].path) ? '_blank' : '_self'}
              >
              { footer.TextWithPaths[1].text }
              
            </Styled.AnchorStyle>
            <Styled.Clear></Styled.Clear>
            <Styled.AnchorStyle href={
                              isExternalUrlhref(footer?.TextWithPaths[2].path)
                              ? footer?.TextWithPaths[2].path
                              : addTrailingSlash( process.env.GATSBY_DOMAIN_URL + footer?.TextWithPaths[2].path )
                          }
                          target={isExternalUrl(footer?.TextWithPaths[2].path) ? '_blank' : '_self'}
              >
              { footer.TextWithPaths[2].text }
              
            </Styled.AnchorStyle>
          </Styled.FootRight>
          <Styled.Clear>&nbsp;</Styled.Clear>
        </Styled.FooterInnerContainer>
      </Styled.FooterContainer>
    </>
  );
};

export default Footer;

import styled from 'styled-components';

export const HtmlSiteMapHeader = styled.p`
  padding-left: ${(props) => props.$leftSpace * 30}px;
  margin-bottom: 20px;
`;
export const HtmlSitemapLink = styled.a`
  font-size: 18px;
`;

export const HtmlSitemapMainDiv = styled.div`
  margin: 30px;
`;
export const linkBottomMargin = styled.span`
  margin-bottom: 5px;
`;